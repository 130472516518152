import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import Section from 'containers/section';
import Image from 'components/image';
import Title from 'components/title';
import { theme } from 'constants/theme';
import { SwiggleIcon } from 'components/icon';

const Thanks = ({ data }) => (
  <Layout headerTheme="dark">
    <Head pageTitle={data.thanksJson.title} />
    <Section bg="#E3C7B1" type="block">
      <SwiggleIcon />
      <Title as="h2" size="giant" color={theme.colors.base} align="center">
        Thank you.
      </Title>
      <Title as="p" size="small" color={theme.colors.base} align="center">
        We will get back to you ASAP about your enquiry.
      </Title>
      <Image
        src={data.thanksJson.heroImage.image}
        alt={data.thanksJson.heroImage.title}
      />
    </Section>
  </Layout>
);

Thanks.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Thanks;

export const query = graphql`
  query ThanksQuery {
    thanksJson {
      title
      heroImage {
        image {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
      }
    }
  }
`;
